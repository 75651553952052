<template lang="pug">
v-container()
  FwdHeadingLarge order settings
  v-layout.mt-5(row wrap)
    v-flex(xs6).pr-3
      Presses
    v-flex(xs6).pl-3
      ProductionInstanceTypes
  v-layout.mt-5(row wrap)
    v-flex(xs6).pr-3
    v-flex(xs6).pl-3
</template>

<script>
import Presses from '@/modules/presses/views/list'
import ProductionInstanceTypes from '@/modules/production-instance-types/views/list'

export default {
  components: {
    Presses,
    ProductionInstanceTypes
  }
}
</script>
